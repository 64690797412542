/**
 * 接口域名的管理
 */
const base = {
  // 生产
  baseURL: process.env.VUE_APP_BASE_API_URL,
  // baseURL: "https://api.moxingshu.com.cn",
  // 预发布
  // baseURL: 'https://pre-api.moxingshu.com.cn',
  // 测试
  // baseURL: "http://apidev.mindmap.x-tral.cn",
  // baseURL: "https://dev-api.moxingshu.com.cn",
  otherURL: "",
  yjs: "yjs.moxingshu.cn",
};
export default base;
