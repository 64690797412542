import { isWin } from "../components/antv-mindmap/mxs-mindmap.es";

export const LoginState = {
  loginIn: 0, // 正在登录
  hasLogin: 1, // 已经登陆
  noLogin: 2, // 没有登陆
  errorLogin: 99,
};

export const DragShowMode = {
  default: 0,
  full: 1,
  hide: 2,
};
export const DragCrossMode = {
  enabled: 0,
  disabled: 1,
  leftDisabled: 2,
  rightDisabled: 3,
};

export const defaultTheme = "dark7";

//配置快捷键
export const shortcutKeys = [
  {
    eventKey: "event-article",
    key: "open-left", //侧边栏
    title: "开关侧边栏",
    shortcutKey: "Meta+L",
    shortcutText: "⌘ + L",
    checkInput: true, //具有输入focus的时候快捷键不生效
    enabled: true, //是否开启快捷键
    icon: "new-69",
  },
  {
    eventKey: "event-article",
    key: "open-mind", //开关导图
    title: "开关导图",
    shortcutKey: "Meta+M",
    shortcutText: "⌘ + M",
    checkInput: false,
    enabled: true, //是否开启快捷键
    icon: "new-11",
  },
  {
    eventKey: "event-article",
    key: "open-document", //开关文档
    title: "开关文档",
    shortcutKey: "Meta+D",
    shortcutText: "⌘ + D",
    checkInput: false,
    enabled: true, //是否开启快捷键
    icon: "file",
  },
  {
    eventKey: "event-article",
    key: "doc-mind", //文档导图切换
    title: "文档导图切换",
    shortcutKey: "Meta+K",
    shortcutText: "⌘ + K",
    checkInput: false,
    enabled: true, //是否开启快捷键
    icon: "new-11",
  },
  // {
  //   eventKey: "event-article",
  //   key: "open-ai", //打开AI
  //   title: "打开AI",
  //   shortcutKey: "Meta+B",
  //   shortcutText: "⌘ + B",
  //   checkInput: false,
  //   enabled: true, //是否开启快捷键
  // },
  {
    eventKey: "event-article",
    key: "new-item", //新建模型
    title: "新建模型",
    shortcutKey: "Meta+Enter",
    shortcutText: "⌘ + Enter",
    checkInput: true,
    enabled: true, //是否开启快捷键
    icon: "new-10",
  },
  {
    eventKey: "event-article",
    key: "del-item",
    title: "删除模型",
    shortcutKey: "Meta+Delete",
    shortcutText: "⌘ + Del",
    checkInput: true,
    enabled: true,
    icon: "new-15",
  },
  {
    eventKey: "event-article",
    key: "move-item",
    title: "移动模型",
    shortcutKey: "Meta+X",
    shortcutText: "⌘ + X",
    checkInput: true,
    enabled: true,
    icon: "new-16",
  },
  {
    eventKey: "event-article",
    key: "copy-item",
    title: "复制模型",
    shortcutKey: "Meta+C",
    shortcutText: "⌘ + C",
    checkInput: true,
    enabled: true,
    icon: "new-20",
  },
];
export const mindShortcutKeys = [
  {
    eventKey: "event-mindmap",
    key: "add-item",
    title: "添加模型",
    shortcutKey: "Tab",
    shortcutText: "Tab",
    checkInput: false,
    enabled: false,
    icon: "new-10",
  },
  {
    eventKey: "event-mindmap",
    key: "del-item",
    title: "删除模型",
    shortcutKey: "Del",
    shortcutText: "Del",
    checkInput: false,
    enabled: false,
    icon: "new-15",
  },
  {
    eventKey: "event-mindmap",
    key: "move-item",
    title: "移动模型",
    shortcutKey: "Meta+X",
    shortcutText: "⌘ + X",
    checkInput: false,
    enabled: false,
    icon: "new-16",
  },
  {
    eventKey: "event-mindmap",
    key: "copy-item",
    title: "复制模型",
    shortcutKey: "Meta+C",
    shortcutText: "⌘ + C",
    checkInput: false,
    enabled: false,
    icon: "new-20",
  },
  {
    eventKey: "event-mindmap",
    key: "copy-item",
    title: "放大",
    shortcutKey: "Meta+滚轮↑",
    shortcutText: "⌘ + 滚轮↑",
    checkInput: false,
    enabled: false,
    icon: "new-88",
  },
  {
    eventKey: "event-mindmap",
    key: "copy-item",
    title: "缩小",
    shortcutKey: "Meta+滚轮↓",
    shortcutText: "⌘ + 滚轮↓",
    checkInput: false,
    enabled: false,
    icon: "new-89",
  },
];
export const editorShortcutKeys = [
  {
    eventKey: "event-mxsEditor",
    key: "bold-item",
    title: "加粗",
    shortcutKey: "Meta+B",
    shortcutText: "⌘ + B",
    checkInput: false,
    enabled: false,
    icon: "new-75",
  },
  {
    eventKey: "event-mxsEditor",
    key: "italic-item",
    title: "斜体",
    shortcutKey: "Meta+I",
    shortcutText: "⌘ + I",
    checkInput: false,
    enabled: false,
    icon: "new-76",
  },
  {
    eventKey: "event-mxsEditor",
    key: "underline-item",
    title: "下划线",
    shortcutKey: "Meta+U",
    shortcutText: "⌘ + U",
    checkInput: false,
    enabled: false,
    icon: "new-77",
  },
  {
    eventKey: "event-mxsEditor",
    key: "link-item",
    title: "插入链接",
    shortcutKey: "Meta+K",
    shortcutText: "⌘ + K",
    checkInput: false,
    enabled: false,
    icon: "new-78",
  },
  {
    eventKey: "event-mxsEditor",
    key: "deleteLine-item",
    title: "删除线",
    shortcutKey: "Meta+Shift+X",
    shortcutText: "⌘ + Shift + K",
    checkInput: false,
    enabled: false,
    icon: "new-79",
  },
  {
    eventKey: "event-mxsEditor",
    key: "Code-item",
    title: "代码",
    shortcutKey: "Meta+Shift+C",
    shortcutText: "⌘ + Shift + C",
    checkInput: false,
    enabled: false,
    icon: "new-80",
  },
  {
    eventKey: "event-mxsEditor",
    key: "H1-item",
    title: "一级标题",
    shortcutKey: "Meta+Alt+1",
    shortcutText: "⌘ + Alt + 1",
    checkInput: false,
    enabled: false,
    icon: "new-81",
  },
  {
    eventKey: "event-mxsEditor",
    key: "H2-item",
    title: "二级标题",
    shortcutKey: "Meta+Alt+2",
    shortcutText: "⌘ + Alt + 2",
    checkInput: false,
    enabled: false,
    icon: "new-82",
  },
  {
    eventKey: "event-mxsEditor",
    key: "H3-item",
    title: "三级标题",
    shortcutKey: "Meta+Alt+3",
    shortcutText: "⌘ + Alt + 3",
    checkInput: false,
    enabled: false,
    icon: "new-83",
  },
  {
    eventKey: "event-mxsEditor",
    key: "text-item",
    title: "普通文本",
    shortcutKey: "Meta+Alt+0",
    shortcutText: "⌘ + Alt + 0",
    checkInput: false,
    enabled: false,
    icon: "new-84",
  },
  {
    eventKey: "event-mxsEditor",
    key: "unorderedList-item",
    title: "无序列表",
    shortcutKey: "Meta+Shift+8",
    shortcutText: "⌘ + Shift + 8",
    checkInput: false,
    enabled: false,
    icon: "new-85",
  },
  {
    eventKey: "event-mxsEditor",
    key: "orderedList-item",
    title: "有序列表",
    shortcutKey: "Meta+Shift+7",
    shortcutText: "⌘ + Shift + 7",
    checkInput: false,
    enabled: false,
    icon: "new-86",
  },
  {
    eventKey: "event-mxsEditor",
    key: "selectAll-item",
    title: "全选",
    shortcutKey: "Meta+A",
    shortcutText: "⌘ + A",
    checkInput: false,
    enabled: false,
    icon: "new-87",
  },
];
export const markdownShortcutKeys = [
  {
    eventKey: "event-mxsEditor",
    key: "H1-item",
    title: "一级标题",
    shortcutKey: "# 空格",
    shortcutText: "# 空格",
    checkInput: false,
    enabled: false,
    icon: "new-81",
  },
  {
    eventKey: "event-mxsEditor",
    key: "H2-item",
    title: "二级标题",
    shortcutKey: "## 空格",
    shortcutText: "## 空格",
    checkInput: false,
    enabled: false,
    icon: "new-82",
  },
  {
    eventKey: "event-mxsEditor",
    key: "H3-item",
    title: "三级标题",
    shortcutKey: "### 空格",
    shortcutText: "### 空格",
    checkInput: false,
    enabled: false,
    icon: "new-83",
  },
  {
    eventKey: "event-mxsEditor",
    key: "unorderedList-item",
    title: "无序列表",
    shortcutKey: "- 空格 或 * 空格",
    shortcutText: "- 空格 或 * 空格",
    checkInput: false,
    enabled: false,
    icon: "new-85",
  },
  {
    eventKey: "event-mxsEditor",
    key: "orderedList-item",
    title: "有序列表",
    shortcutKey: "1. 空格",
    shortcutText: "1. 空格",
    checkInput: false,
    enabled: false,
    icon: "new-86",
  },
  {
    eventKey: "event-mxsEditor",
    key: "inIndent-item",
    title: "增加缩进",
    shortcutKey: "Tab",
    shortcutText: "Tab",
    checkInput: false,
    enabled: false,
    icon: "new-90",
  },
  {
    eventKey: "event-mxsEditor",
    key: "deIndent-item",
    title: "减少缩进",
    shortcutKey: "Shift+Tab",
    shortcutText: "Shift + Tab",
    checkInput: false,
    enabled: false,
    icon: "new-91",
  },
  {
    eventKey: "event-mxsEditor",
    key: "quote-item",
    title: "引用",
    shortcutKey: "> 空格",
    shortcutText: "> 空格",
    checkInput: false,
    enabled: false,
    icon: "new-92",
  },
  {
    eventKey: "event-mxsEditor",
    key: "code-item",
    title: "代码",
    shortcutKey: "`代码` 空格",
    shortcutText: "`代码` 空格",
    checkInput: false,
    enabled: false,
    icon: "new-80",
  },
  {
    eventKey: "event-mxsEditor",
    key: "bold-item",
    title: "加粗",
    shortcutKey: "**文字** 空格",
    shortcutText: "**文字** 空格",
    checkInput: false,
    enabled: false,
    icon: "new-75",
  },
  {
    eventKey: "event-mxsEditor",
    key: "italic-item",
    title: "斜体",
    shortcutKey: "*文字* 空格",
    shortcutText: "*文字* 空格",
    checkInput: false,
    enabled: false,
    icon: "new-76",
  },
  {
    eventKey: "event-mxsEditor",
    key: "underline-item",
    title: "下划线",
    shortcutKey: "~文字~ 空格",
    shortcutText: "~文字~ 空格",
    checkInput: false,
    enabled: false,
    icon: "new-77",
  },
  {
    eventKey: "event-mxsEditor",
    key: "deleteLine-item",
    title: "删除线",
    shortcutKey: "~~文字~~ 空格",
    shortcutText: "~~文字~~ 空格",
    checkInput: false,
    enabled: false,
    icon: "new-79",
  },
  {
    eventKey: "event-mxsEditor",
    key: "separationLine-item",
    title: "分隔线",
    shortcutKey: "--- 或 ***",
    shortcutText: "--- 或 ***",
    checkInput: false,
    enabled: false,
    icon: "new-93",
  },
];
shortcutKeys.forEach((item) => {
  if (isWin) {
    if (item.shortcutKey) {
      item.shortcutKey = item.shortcutKey.replace("Meta", "Ctrl");
    }
    if (item.shortcutText) {
      item.shortcutText = item.shortcutText.replace("⌘", "Ctrl");
    }
  }
  item.shortcutKey = item.shortcutKey.toLowerCase();
});
mindShortcutKeys.forEach((item) => {
  if (isWin) {
    if (item.shortcutKey) {
      item.shortcutKey = item.shortcutKey.replace("Meta", "Ctrl");
    }
    if (item.shortcutText) {
      item.shortcutText = item.shortcutText.replace("⌘", "Ctrl");
    }
  }
  item.shortcutKey = item.shortcutKey.toLowerCase();
});
editorShortcutKeys.forEach((item) => {
  if (isWin) {
    if (item.shortcutKey) {
      item.shortcutKey = item.shortcutKey.replace("Meta", "Ctrl");
    }
    if (item.shortcutText) {
      item.shortcutText = item.shortcutText.replace("⌘", "Ctrl");
    }
  }
  item.shortcutKey = item.shortcutKey.toLowerCase();
});
