/**
 * account模块接口列表
 */
import base from "./base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块

const account = {
  // 微信登录
  webWechatLogin(params) {
    return axios.post(`${base.baseURL}/api/Account/WebWechatLogin`, params);
  },
  // 绑定/重新绑定微信号
  bindWebWechat(params) {
    return axios.post(`${base.baseURL}/api/Account/BindWebWechat`, params);
  },
  // 绑定手机号
  bindMobile(params) {
    return axios.post(`${base.baseURL}/api/Account/BindMobile`, params);
  },
  // 发送短信(绑定手机号用)
  sendBindMobileSms(params) {
    return axios.post(`${base.baseURL}/api/Account/SendBindMobileSms`, params);
  },
  // 获取验证码
  // 接口返回JPEG文件的二进制流，设置请求数据类型：responseType:'arraybuffer'
  captcha(mobile) {
    return axios.get(`${base.baseURL}/api/Account/Captcha?mobile=${mobile}`, {
      responseType: "arraybuffer",
    });
  },
  // 手机登陆
  mobileLogin(params) {
    return axios.post(`${base.baseURL}/api/Account/MobileLogin`, params);
  },
  // 获取短信验证码
  sendSms(params) {
    return axios.post(`${base.baseURL}/api/Account/SendSms`, params);
  },

  // 发送短信(登陆用)(滑动验证)
  sendAfsSms(params) {
    return axios.post(`${base.baseURL}/api/Account/SendAfsSms`, params);
  },
  //获取用户信息
  getUserInfo() {
    return axios.get(`${base.baseURL}/api/Account/AccountInfo`);
  },

  // 注销用户(删除用户账户和内容信息)
  logOff() {
    return axios.post(`${base.baseURL}/api/Account/LogOff`);
  },

  // 开启密码登录功能
  addPwd(params) {
    return axios.post(`${base.baseURL}/api/Account/AddPwd`, params);
  },

  // 密码登录
  pwdLogin(params) {
    return axios.post(`${base.baseURL}/api/Account/PwdLogin`, params);
  },

  // 检查账号名有效性
  checkAccountName(params) {
    return axios.post(`${base.baseURL}/api/Account/CheckAccountName`, params);
  },

  // 修改密码
  updatePwd(params) {
    return axios.post(`${base.baseURL}/api/Account/UpdatePwd`, params);
  },

  // 更改昵称(10字以内)
  updateNickname(params) {
    return axios.post(`${base.baseURL}/api/Account/UpdateNickname`, params);
  },

  // 更换头像(5M以内)
  updateHeadImage(params) {
    return axios.post(`${base.baseURL}/api/Account/UpdateHeadImage`, params, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};
export default account;
