<template>
  <el-dialog
    title="编辑头像"
    v-model="dialogVisible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="600px"
  >
    <div style="display: flex" class="avatar2">
      <div class="avatar-left">
        <div v-show="!options.img">
          <el-upload
            ref="upload"
            action=""
            style="text-align: center; margin-bottom: 24px"
            :on-change="uploads"
            accept="image/png, image/jpeg, image/jpg"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button type="primary" ref="uploadBtn">选择图片</el-button>
          </el-upload>
          <div>支持jpg、png格式的图片，大小不超过3M</div>
        </div>
        <div v-show="options.img" class="avatar-left-crop">
          <vueCropper
            class="crop-box"
            ref="cropper"
            :img="options.img"
            :autoCrop="options.autoCrop"
            :fixedBox="options.fixedBox"
            :canMoveBox="options.canMoveBox"
            :autoCropWidth="options.autoCropWidth"
            :autoCropHeight="options.autoCropHeight"
            :centerBox="options.centerBox"
            @realTime="realTime"
          >
          </vueCropper>
          <p class="avatar-left-p">
            鼠标滚轮缩放控制图片显示大小，鼠标拖拽调整显示位置
          </p>
        </div>
      </div>
      <div class="avatar-right">
        <div
          class="avatar-right-div"
          v-for="(item, index) in previewsDiv"
          :key="index"
          :style="item.style"
        >
          <div
            v-show="options.img"
            :class="previews.div"
            class="avatar-right-previews"
            :style="item.zoomStyle"
          >
            <img :src="previews.url" :style="previews.img" />
          </div>
        </div>
        <div class="avatar-right-text">
          <el-button v-if="options.img" type="text" @click="uploadPreviews"
            >重新上传</el-button
          >
          <span v-else>预览</span>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="getCrop">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { VueCropper } from "vue-cropper";
export default {
  name: "avatarEdit",
  components: {
    VueCropper,
  },
  props: {
    changeAvatar: { type: Function, default: undefined },
    user: { type: String, default: undefined },
  },
  data() {
    return {
      dialogVisible: true,
      //vueCropper组件 裁剪配置信息
      options: {
        img: "", //原图文件
        autoCrop: true, //默认生成截图框
        fixedBox: true, //固定截图框大小
        canMoveBox: false, //截图框不能拖动
        autoCropWidth: 200, //截图框宽度
        autoCropHeight: 200, //截图框高度
        centerBox: true, //截图框被限制在图片里面
      },
      //实时预览图数据
      previews: {},
      //实时预览图样式
      previewsDiv: [
        //108px 预览样式
        {
          style: {
            width: "108px",
            height: "108px",
            margin: "0 auto",
          },
          zoomStyle: {
            zoom: 0.54,
          },
        },
        //68px 预览样式
        {
          style: {
            width: "68px",
            height: "68px",
            margin: "27px auto",
          },
          zoomStyle: {
            zoom: 0.34,
          },
        },
        //48px 预览样式
        {
          style: {
            width: "48px",
            height: "48px",
            margin: "0 auto",
          },
          zoomStyle: {
            zoom: 0.24,
          },
        },
      ],
    };
  },

  methods: {
    //读取原图
    uploads(file) {
      const isIMAGE =
        file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const isLt3M = file.raw.size / 1024 / 1024 < 3;
      if (!isIMAGE) {
        this.$message({
          showClose: true,
          message: "请选择 jpg、png 格式的图片！",
          type: "error", //提示类型
        });
        return false;
      }
      if (!isLt3M) {
        this.$message({
          showClose: true,
          message: "上传图片大小不能超过 3MB",
          type: "error", //提示类型
        });
        return false;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = (e) => {
        this.options.img = e.target.result; //base64
      };
    },
    //实时预览数据
    realTime(data) {
      this.previews = data;
    },
    //重新上传
    uploadPreviews() {
      this.$refs.uploadBtn.$el.click();
    },
    //获取截图信息
    getCrop() {
      // 获取截图的 base64 数据
      //   this.$refs.cropper.getCropData((data) => {
      //     console.log(data);
      //   });
      // 获取截图的 blob 数据
      this.$refs.cropper.getCropBlob((data) => {
        let formData = new FormData();
        formData.append("file", data, this.user.accountId);
        this.$api.account.updateHeadImage(formData).then((res) => {
          if (res.data.isSuccess) {
            this.$emit("changeAvatar", res.data.data);
          } else {
            this.$root.tips("error", res.data.message);
          }
        });
      });
    },
    //关闭弹框
    closeDialog() {
      this.$parent.avatarUploadVisible = false;
      //重置 data 数据
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style lang="scss" scoped>
:deep .el-dialog__header {
  padding: 24px 0 11px 28px;
}

:deep .el-dialog__title {
  color: #333333;
}

:deep .el-dialog__body {
  padding: 0 28px;
}

:deep .el-dialog__footer {
  padding: 20px 28px;

  .el-button {
    width: 145px;
  }
}

.avatar2 {
  display: flex;

  .avatar-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 400px;
    background-color: #f0f2f5;
    margin-right: 10px;
    border-radius: 4px;

    .avatar-left-crop {
      width: 400px;
      height: 400px;
      position: relative;

      .crop-box {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        overflow: hidden;
      }
    }

    .avatar-left-p {
      text-align: center;
      width: 100%;
      position: absolute;
      bottom: 20px;
      color: #ffffff;
      font-size: 14px;
    }
  }

  .avatar-right {
    width: 150px;
    height: 400px;
    background-color: #f0f2f5;
    border-radius: 4px;
    padding: 16px 0;
    box-sizing: border-box;

    .avatar-right-div {
      // border: 3px solid #ffffff;
      border-radius: 50%;
    }

    .avatar-right-previews {
      width: 200px;
      height: 200px;
      overflow: hidden;
      border-radius: 20px;
    }

    .avatar-right-text {
      text-align: center;
      margin-top: 50px;
      font-size: 14px;

      :deep .el-button {
        padding: 0;
      }

      span {
        color: #666666;
      }
    }
  }
}
[class*=" dark"] {
  .avatar2 .avatar-left,
  .avatar2 .avatar-right {
    background-color: #141414;
  }
}
</style>
